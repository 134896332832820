import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import {
    Grid,
    Paper,
    Tabs,
    Tab,
    Button,
    withWidth,
    makeStyles,
    Title,
    Typography,
    Divider,
} from "@material-ui/core";

import { AuthContext } from "../../context/Auth/AuthContext";
import whatsBackground from "../../assets/wa-background.png";
import { ChatModal } from "../../components/ChatModal";
import ChatList from "../../components/ChatList";
import ChatMessages from "../../components/ChatMessages";
import { isWidthUp } from "@material-ui/core";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import { isObject, has } from "lodash";
import { BorderRight } from "@material-ui/icons";
import { set } from "date-fns";
import openSocket from "../../services/socket-io";
import { padding } from "@mui/system";
import { toast } from "react-toastify";
import toastError from "../../errors/toastError";
import { ReactComponent as ArrowLeft } from "bootstrap-icons/icons/arrow-left-circle-fill.svg";

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        display: "flex",
        flexDirection: "column",
        position: "relative",
        flex: 1,
        padding: theme.spacing(2),
        height: `calc(100% - 48px)`,
        overflowY: "hidden",
        border: "1px solid rgba(0, 0, 0, 0.12)",
        backgroundImage: `url(${whatsBackground})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        [theme.breakpoints.down("sm")]: {
            backgroundColor: "#fff",
            padding: 0,
          },
    },
    gridContainer: {
        flex: 1,
        height: "100%",
        border: "1px solid rgba(0, 0, 0, 0.12)",
        backgroundColor: "inherit",
    },
    gridItem: {
        height: "100%",
    },
    gridItemTab: {
        height: "92%",
        width: "100%",
    },
    btnContainer: {
        textAlign: "right",
        padding: 10,
    },
    drawerPaper: {
        backgroundColor: "#ededed",
        //backgroundColor: "#151d86",
        position: "relative",
        whiteSpace: "nowrap",
        height: "100%",
    },
    titleContainer: {
        textAlign: "left",
        padding: 10,
        marginLeft: 16,
        textTransform: "uppercase",
    },
    container: {
        display: "flex",
        itemAlign: "center",
    },
}));

function InternalChat(props) {
    const classes = useStyles();
    const { user } = useContext(AuthContext);
    const history = useHistory();

    const [showDialog, setShowDialog] = useState(false);
    const [dialogType, setDialogType] = useState("new");
    const [currentChat, setCurrentChat] = useState({});
    const [chats, setChats] = useState([]);
    const [chatsPageInfo, setChatsPageInfo] = useState({ hasMore: false });
    const [messages, setMessages] = useState([]);
    const [messagesPageInfo, setMessagesPageInfo] = useState({ hasMore: false });
    const [messagesPage, setMessagesPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [tab, setTab] = useState(0);
    const isMounted = useRef(true);
    const scrollToBottomRef = useRef();
    //const { id } = useParams();


    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (isMounted.current) {
            findChats().then((data) => {

                if (data.length > 0) {
                    setChats(data);
                    setTab(1);
                    setChatsPageInfo({ hasMore: true });
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isObject(currentChat) && has(currentChat, "id")) {
            findMessages(currentChat.id).then(() => {
                if (typeof scrollToBottomRef.current === "function") {
                    setTimeout(() => {
                        scrollToBottomRef.current();
                    }, 300);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentChat]);

    useEffect(() => {

        const socket = openSocket();

        socket.on("chat", (data) => {
            if (data.action === "create") {
                setChats((prev) => [data.chat, ...prev]);
            }

            if (data.action === "delete") {
                setChats((prev) => prev.filter((chat) => chat.id !== data.chat.id));
                setCurrentChat({});
                setMessages([]);
                setMessagesPage(1);
                setMessagesPageInfo({ hasMore: false });
                history.push("/chats");
            }

            if (data.action === "update") {
                setChats((prev) => {
                    return prev.map((chat) => {
                        if (chat.id === data.chat.id) {
                            // Atualiza o chat com as novas informações
                            return { ...data.chat };
                        }
                        // Mantém os outros chats inalterados
                        return chat;
                    });
                });
                setCurrentChat(data.chat);
                history.push("/chats");
            };
        });

        socket.on("chatMessage", (data) => {
            if (data.action === "create") {
                if (data.chat.id === currentChat.id) {
                    setMessages((prev) => [...prev, data.newMessage]);
                    const changedChats = chats.map((chat) => {
                        if (chat.id === data.newMessage.chatId) {
                            setCurrentChat(data.chat);
                            return {
                                ...data.chat,
                            };
                        }
                        return chat;
                    });
                    setChats(changedChats);
                    history.push("/chats");
                }

                if (typeof scrollToBottomRef.current === "function") {
                    setTimeout(() => {
                        scrollToBottomRef.current();
                    }, 300);
                }
            }

            if (data.action === "update") {
                console.log("update message");
                if (data.chat.id === currentChat.id) {
                    const changedChats = chats.map((chat) => {
                        if (chat.id === data.chat.id) {
                            setCurrentChat(data.chat);
                            return {
                                ...data.chat,
                            };
                        }
                        return chat;
                    });
                    setChats(changedChats);
                    history.push("/chats");
                }

                if (typeof scrollToBottomRef.current === "function") {
                    setTimeout(() => {
                        scrollToBottomRef.current();
                    }, 300);
                }
            }
        });

        return () => {
            socket.disconnect();
        }

    }, [chats, currentChat.id, history]);


    const selectChat = (chat) => {
        try {
            setMessages([]);
            setMessagesPage(1);
            setCurrentChat(chat);
            setTab(1);
            history.push("/chats");
        } catch (err) { }
    };

    const deleteChat = async (chat) => {
        try {
            await api.delete(`/chats/${chat.id}`);
        } catch (err) {
            toastError(err);
        }
    };

    const findMessages = async (chatId) => {
        setLoading(true);
        try {
            const { data } = await api.get(
                `/chats/${chatId}/messages?pageNumber=${messagesPage}`
            );
            setMessagesPage((prev) => prev + 1);
            setMessagesPageInfo(data);
            setMessages((prev) => [...data.messages, ...prev]);
        } catch (err) { }
        setLoading(false);
    };

    const loadMoreMessages = async () => {
        if (!loading) {
            findMessages(currentChat.id);
        }
    };

    const findChats = async () => {
        try {
            const { data } = await api.get("/chats");
            return data;
        } catch (err) {
            console.log(err);
        }
    };

    const renderGrid = () => {
        return (
            <>
                <Grid className={classes.gridContainer} container>
                    <Grid className={classes.drawerPaper} md={3} item>
                        <div className={classes.container}>
                            <div className={classes.titleContainer}>
                                <Typography variant="h6" color="primary">Chat Interno</Typography>
                            </div>
                            <div className={classes.btnContainer}>
                                <Button
                                    onClick={() => {
                                        setDialogType("new");
                                        setShowDialog(true);
                                    }}
                                    color="primary"
                                    variant="contained"
                                >
                                    Nova
                                </Button>
                            </div>
                        </div>
                        <Divider sx={{ my: 2 }}></Divider>
                        <ChatList
                            chats={chats}
                            pageInfo={chatsPageInfo}
                            loading={loading}
                            handleSelectChat={(chat) => selectChat(chat)}
                            handleDeleteChat={(chat) => deleteChat(chat)}
                            handleEditChat={() => {
                                setDialogType("edit");
                                setShowDialog(true);
                            }}
                        />
                    </Grid>
                    <Divider></Divider>
                    <Grid className={classes.gridItem} md={9} item>
                        {isObject(currentChat) && has(currentChat, "id") && (
                            <ChatMessages
                                chat={currentChat}
                                scrollToBottomRef={scrollToBottomRef}
                                pageInfo={messagesPageInfo}
                                messages={messages}
                                loading={loading}
                                handleLoadMore={loadMoreMessages}
                            />
                        )}
                    </Grid>
                </Grid>
            </>
        );
    };

    const renderTab = () => {
        return (
            <Grid className={classes.gridContainer} container>
                <Grid md={12} item>
                    <Tabs
                        value={tab}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={(e, v) => setTab(v)}
                        aria-label="disabled tabs example"
                    >
                        <Tab label="Chats" />
                        <Tab label="Mensagens" />
                    </Tabs>
                </Grid>
                {tab === 0 && (
                    <Grid className={classes.gridItemTab} md={12} item>
                        <div className={classes.btnContainer}>
                            <Button
                                onClick={() => setShowDialog(true)}
                                color="primary"
                                variant="contained"
                            >
                                Novo
                            </Button>
                        </div>
                        <ChatList
                            chats={chats}
                            pageInfo={chatsPageInfo}
                            loading={loading}
                            handleSelectChat={(chat) => selectChat(chat)}
                            handleDeleteChat={(chat) => deleteChat(chat)}
                        />
                    </Grid>
                )}
                {tab === 1 && (
                    <Grid className={classes.gridItemTab} md={12} item>
                        {isObject(currentChat) && has(currentChat, "id") && (
                            <ChatMessages
                                chat={currentChat}
                                scrollToBottomRef={scrollToBottomRef}
                                pageInfo={messagesPageInfo}
                                messages={messages}
                                loading={loading}
                                handleLoadMore={loadMoreMessages}
                            />
                        )}
                    </Grid>
                )}
            </Grid>
        );
    };

    return (
        <>
            <ChatModal
                type={dialogType}
                open={showDialog}
                chat={currentChat}
                handleLoadNewChat={(data) => {
                    setMessages([]);
                    setMessagesPage(1);
                    setCurrentChat(data);
                    setTab(1);
                    //history.push(`/chats/${data.uuid}`);
                }}
                handleClose={() => setShowDialog(false)}
            />
            <Paper className={classes.mainContainer}>
                {isWidthUp("md", props.width) ? renderGrid() : renderTab()}
            </Paper>
        </>
    );
}

export default withWidth()(InternalChat);